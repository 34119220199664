<template>
  <div>
    <form @submit.prevent="submit">
      <div class="font-bold mb-5 text-lg">
        Employment Information
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
        <div class="col-span-1">
          <form-group
            v-model="form.data.employer.value"
            name="employer"
            :form="form"
            placeholder=""
            class="mb-0"
          >
            Employer *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.position.value"
            :form="form"
            name="position"
            class="mb-0"
          >
            Job Title *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.emp_type.value"
            :form="form"
            :options="employmentTypes"
            type="select"
            name="emp_type"
            class="mb-0"
          >
            Employment Type *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.salary.value"
            name="salary"
            :form="form"
            type="money"
            :precision="0"
            placeholder="Salary"
            class="mb-0"
          >
            Monthly Salary *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.salary_day.value"
            name="salary_day"
            :form="form"
            class="mb-0"
            type="select"
            :options="daysOfTheMonth"
            select-display-type="grid"
            :select-grid-columns="4"
          >
            Salary Day *
          </form-group>
        </div>
        <div class="col-span-1 md:col-span-2">
          <form-group
            v-model="form.data.company_address.value"
            :form="form"
            type="textarea"
            name="company_address"
            class="mb-0"
          >
            Company Address *
          </form-group>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-blue"
        :disabled="form.loading"
      >
        <sm-loader class="sm-loader-white" v-if="form.loading" />
        <span v-else>Submit Employment Info</span>
      </button>
    </form>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        User Profile Updated
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedUser: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      employmentTypes: [
        'Full Time',
        'Part Time',
        'Contract',
      ],
      form: this.$options.basicForm(
        [
          'employer',
          'emp_type',
          'salary',
          'salary_day',
          'company_address',
          'position',
        ],
        {
          industries: [
            'Advertising',
            'Agriculture',
            'Communications',
            'Construction',
            'Creative Industries',
            'Education',
            'Entertainment',
            'Farming',
            'Fashion',
            'Finance',
            'Green',
            'Heavy',
            'Hospitality',
            'Information',
            'Information Technology',
            'Infrastructure',
            'IT',
            'Light',
            'Manufacturing',
            'Materials',
            'Media',
            'Music',
            // 'Primary',
            'Publishing',
            'Retail',
            'Robotics',
            // 'Secondary',
            'Service',
            'Space',
            'Space',
            'Technology',
            'Telecom',
            // , 'Tertiary Sector'
          ],
        },
      ),
    };
  },
  computed: {
    daysOfTheMonth() {
      const days = [];

      for (let i = 0; i < 31; i += 1) {
        const day = i + 1;
        const value = day;
        const title = day.toPosition();
        days.push({ title, value });
      }

      return days;
    },
  },
  mounted() {
    this.mapUserData();
  },
  methods: {
    mapUserData() {
      if (this.selectedUser?.profile) {
        Object.keys(this.form.data).forEach((key) => {
          if (key === 'workplace_email') {
            this.form.data[key].value = this.selectedUser?.official_email || '';
          } else {
            this.form.data[key].value = this.selectedUser?.profile[key] || '';
          }
        });
      }
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return;
      }

      this.form.loading = true;
      this.form.error = false;

      await this.sendRequest('admin.users.profile.employment.employed', this.selectedUser?.id, {
        data: this.getFormData(),
        success: (response) => {
          this.$emit('updated', { user: response.data.user });
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.loading = false;
    },
  },
};
</script>
